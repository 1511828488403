import React from "react";

function Faqs() {
  return (
    <section className="question_area section_padding" id="faq">
      <div className="container">
        <div className="section_title">
          <h2>FREQUENTLY ASKED QUESTIONS</h2>
          <p>
            To clarify common understanding questions, here is a list of
            questions and answers of the most frequently asked questions to our
            team.
          </p>
        </div>
        <div className="question">
          <div className="demo faq_question_2">
            <div className="accordion" id="accordionExample">
              <div className="card">
                <div className="card-header" id="headingOne">
                  <h2 className="mb-0">
                    <button
                      type="button"
                      className="btn btn-link collapsed"
                      data-toggle="collapse"
                      data-target="#collapseOne"
                    >
                      <i className="fa fa-plus" /> What is BTCINU?
                    </button>
                  </h2>
                </div>
                <div
                  id="collapseOne"
                  className="collapse "
                  aria-labelledby="headingOne"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    <p>
                      BTCINU is a new Memecoin which leads the crypto industry
                      into a new era of Memecoins! You don’t believe it? Check
                      out the Whitepaper to get more insights about the
                      Solutions of BTCINU.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="headingTwo">
                  <h2 className="mb-0">
                    <button
                      type="button"
                      className="btn btn-link show_colups"
                      data-toggle="collapse"
                      data-target="#collapseTwo"
                    >
                      <i className="fa fa-plus" />
                      Why should I buy BTCINU?
                    </button>
                  </h2>
                </div>
                <div
                  id="collapseTwo"
                  className="collapse"
                  aria-labelledby="headingTwo"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    <p>
                      Answering this question is quite simple. Because with
                      BTCINU we are trying to take the Memecoin market to a new
                      dimension by offering a handful of new solutions for
                      Memecoin investors. Feel free to read the Whitepaper to
                      learn more about these solutions.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="headingThree">
                  <h2 className="mb-0">
                    <button
                      type="button"
                      className="btn btn-link collapsed"
                      data-toggle="collapse"
                      data-target="#collapseThree"
                    >
                      <i className="fa fa-plus" />
                      Where can I buy BTCINU?
                    </button>
                  </h2>
                </div>
                <div
                  id="collapseThree"
                  className="collapse"
                  aria-labelledby="headingThree"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    <p>
                    BTCINU is currently only listed on Uniswap, by entering THE
OFFICIAL CONTRACT ADDRESS. Every other platform or pool is scam!
                    </p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header" id="headingFour">
                  <h2 className="mb-0">
                    <button
                      type="button"
                      className="btn btn-link collapsed"
                      data-toggle="collapse"
                      data-target="#collapseFour"
                    >
                      <i className="fa fa-plus" />
                      How do I stay up to date?
                    </button>
                  </h2>
                </div>
                <div
                  id="collapseFour"
                  className="collapse"
                  aria-labelledby="headingFour"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    <p>
                      Join our telegram community group to be one of the first
                      to know the latest news and announcements. Furthermore you
                      can follow our social media accounts. All links to are at
                      the top of the Website.
                    </p>
                  </div>
                </div>
              </div>
              {/* Faqs 5  */}
              <div className="card">
                <div className="card-header" id="headingFive">
                  <h2 className="mb-0">
                    <button
                      type="button"
                      className="btn btn-link collapsed"
                      data-toggle="collapse"
                      data-target="#collapseFive"
                    >
                      <i className="fa fa-plus" />
                      How can I contact the support?
                    </button>
                  </h2>
                </div>
                <div
                  id="collapseFive"
                  className="collapse"
                  aria-labelledby="headingFive"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    <p>
                      For smaller problems you can ask for help in our telegram
                      community group. In order to receive costumer support on
                      bigger issues please send an e-mail regarding your issues
                      to contact@bitcoininu.io.
                    </p>
                  </div>
                </div>
              </div>
              {/* Faqs 5 end here */}


              {/* Faqs 6 */}
              <div className="card">
                <div className="card-header" id="headingSix">
                  <h2 className="mb-0">
                    <button
                      type="button"
                      className="btn btn-link collapsed"
                      data-toggle="collapse"
                      data-target="#collapseSix"
                    >
                      <i className="fa fa-plus" />
                      How safe is Bitcoin Inu?
                    </button>
                  </h2>
                </div>
                <div
                  id="collapseSix"
                  className="collapse"
                  aria-labelledby="headingSix"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    <p>
                    Bitcoin Inu is not connected to a use case
                    and therefore has the same investment risk every other Memecoin has. But 99% of all the
                    other Memecoins have the problem to be scam, by adding shady coding to the code. We want
                    to offer full transparency and let the German company “SolidProof“ verify the smart contract
                    and scored a perfect score in the <a href="https://app.solidproof.io/projects/btcinu?audit_id=808">audit</a>.
                    </p>
                  </div>
                </div>
              </div>
              {/* Faqs 6 ends here */}

            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Faqs;
