import React from "react";

function HowToBuy() {
  return (
    <section className="how_to_buy_area section_padding" id="how_to_buy">
      <div className="how_to_but_bg">
        <div className="container">
          <div className="how_to_main">
            <div className="section_title">
              <h2>BUY BTCINU SAFE &amp; EASY</h2>
              <p>3 steps, 5 minutes and many gains!</p>
              
            </div>
           

            <div className="how_to_row">
              <div className="how_to_single">
                <span>1</span>
                <h4>Visit Uniswap</h4>
                <p>
                Visit the official site of Uniswap V3 and connect your wallet. Continue by
clicking on "Swap" and pasting the OFFICIAL CONTRACT ADDRESS into the token search
bar “You buy“. Now all you have to do is click on "You pay" and choose your desired
currency for payment.
                </p>
              </div>
              <div className="how_to_single">
                <span>2</span>
                <h4>Choose Amount</h4>
                <p>
                Enter the amount you want to invest into $BTCINU and let Uniswap display how many tokens
you'll receive!
                </p>
              </div>
              <div className="how_to_single">
                <span>3</span>
                <h4>Here You Go!</h4>
                <p>
                  Click on “Buy“ and approve in your wallet. If it does not
                  work, try the in-app browser of your Wallet. You will be able
                  to claim your token on this website when the presale is over!
                </p>
              </div>
              </div>
              
            <div className="watch_tutorial"> 
            <a href="https://youtu.be/BlZCeMXenGo" target="_blank" rel="noreferrer"><div className="watch_tutorial1"><p>How to Buy</p>
             </div></a>
           <a href="https://youtu.be/kizfPqeqCFA" target="_blank" rel="noreferrer">  <button className="howbutton">How to Claim</button></a>

             </div>

          

            <div className="how_to_pp">
              <p>
                Note: EVERY other website, address or payment method is 100%
                scam. ANYONE trying to help you is a scammer!
              </p>
            </div>
            <br /><br />
            <div className="contract_address mobile1-hide">
                <div>Contract Address:</div>  
                <div>0x584a4Dd38d28fD1EA0E147bA7B70AEd29A37e335</div></div>
                <div className="contract_address2 desktop1-hide">
                <div>Contract Address:</div>  
                <div>0x584a4Dd38d28fD1EA0E147bA7B70AEd29A37e335</div></div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HowToBuy;
