import React from "react";

function Features() {
  return (
    <>
      <section className="featear_area section_padding">
        <div className="container">
          <div className="feature_title">
            <h3>FEATURED IN</h3>
          </div>
          <div className="features">
          <div className="feature_item">
              <img src="assets/images/feature/thecoinrepublic.png" alt="" />
            </div>
            {/* <div className="feature_item">
              <img src="assets/images/feature/1.png" alt="" />
            </div>
            <div className="feature_item">
              <img src="assets/images/feature/2.png" alt="" />
            </div>
            <div className="feature_item">
              <img src="assets/images/feature/3.png" alt="" />
            </div>
            <div className="feature_item">
              <img src="assets/images/feature/4.png" alt="" />
            </div>
            <div className="feature_item">
              <img src="assets/images/feature/5.png" alt="" />
            </div>
            <div className="feature_item">
              <img src="assets/images/feature/6.png" alt="" />
            </div> */}


            <div className="feature_item">
              <img src="assets/images/feature/coinmarketcap.png" alt="" />
            </div>
            <div className="feature_item">
              <img src="assets/images/feature/coinbase.png" alt="" />
            </div>
            <div className="feature_item">
              <img src="assets/images/feature/metamask.png" alt="" />
            </div>
            {/* <div className="feature_item">
              <img src="assets/images/feature/trustwallet.png" alt="" />
            </div> */}
            <div className="feature_item">
              <img src="assets/images/feature/geckoterminal.png" alt="" />
            </div>
            <div className="feature_item">
              <img src="assets/images/feature/uniswap.png" alt="" />
            </div>


           


          </div>

          {/* <div className="features">
          <div className="feature_item">
              <img src="assets/images/feature/coinmarketcap.png" alt="" />
            </div>
            <div className="feature_item">
              <img src="assets/images/feature/coinbase.png" alt="" />
            </div>
            <div className="feature_item">
              <img src="assets/images/feature/metamask.png" alt="" />
            </div>
            <div className="feature_item">
              <img src="assets/images/feature/trustwallet.png" alt="" />
            </div>
            </div> */}

        </div>
      </section>
      <section className="change_area section_padding">
        <div className="container">
          <div className="chagne_row">
            <div className="change_left">
              <img src="assets/images/change/change.png" alt="" />
            </div>
            <div className="change_right">
              <h3>THIS IS WHY BTCINU WILL CHANGE THE WORLD OF MEMECOINS!</h3>
              <p>
                BTCINU brings you the new dimension of Memecoins! This is the
                modern solution to make gains with Memecoins fast and easy.
                After a long and intensive research, the team is determined that
                BTCINU is able to combine BTC and SHIB into one concentrated
                MEMECOIN with ENORMOUS POTENTIAL! Thanks to the high level of
                awareness and acceptance of Bitcoin and the massive flood of new
                Memecoin investors through Shiba Inu, BTCINU can combine the
                strength of both predecessors and ATTRACT new investors.
              </p>
              <a href="#Tokenomics" className="btn_3 tokenomics_scroll">
                Learn More
              </a>
            </div>
          </div>
        </div>
        <div className="shape_chagne">
          <img src="assets/images/change/shape.png" alt="" />
        </div>
      </section>
    </>
  );
}

export default Features;
