import React from "react";
import { ConnectButton } from "./SmallComponents/StyledWalletButton";

export default function Header() {
  return (
    <header className="header_area_main" id="header_areaa">
      <div className="container">
        <div className="header_box">
          <div className="logo">
            <a href="#">
              <img src="assets/images/logo.png" alt="" />
              <span>Bitcoin Inu</span>
            </a>
            <a className="bars_menu" href="#">
              <i className="fas fa-bars" />
            </a>
          </div>
          <div className="menu">
            <ul>
              <li>
                <a className="active" href="#">
                  Home
                </a>
              </li>
              {/* <li>
                <a href="#presale_stage">Presale Stages</a>
              </li> */}
              <li>
                <a href="#Tokenomics">Tokenomics</a>
              </li>
              <li>
                <a href="#Roadmap">Roadmap</a>
              </li>
              <li>
                <a href="#faq">FAQ's</a>
              </li>
            </ul>
            <div className="menu_btn desktop_hide">
              <ul className="header_social">
              <li>
                  <a href="https://t.me/btcinucoin" target="_blank">
                    <img src="assets/images/header/teligram.png" alt="" />
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/btcinucoin" target="_blank">
                    <img src="assets/images/header/x.png" alt="" />
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/btcinu" target="_blank">
                    <img src="assets/images/header/instagram.png" alt="" />
                  </a>
                </li>
                <li>
                  <a href="https://youtube.com/@bitcoininu?si=yOo47Q2UKA0AuVog" target="_blank">
                    <img src="assets/images/header/youtube.png" alt="" />
                  </a>
                </li>
              </ul>
              <a href="https://www.dextools.io/app/en/ether/pair-explorer/0x80464c5024253974506e8dd36b8ee49791c1f1ea" target="_blank">
            <button className="btn_5">View Chart</button></a> <br /> <br />
              <ConnectButton />
            </div>
          </div>
          <div className="header_btn mobile_hide">
            <ul className="header_social">
            <li>
                  <a href="https://t.me/btcinucoin" target="_blank">
                    <img src="assets/images/header/teligram.png" alt="" />
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/btcinucoin" target="_blank">
                    <img src="assets/images/header/x.png" alt="" />
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/btcinu" target="_blank">
                    <img src="assets/images/header/instagram.png" alt="" />
                  </a>
                </li>
                <li>
                  <a href="https://youtube.com/@bitcoininu?si=yOo47Q2UKA0AuVog" target="_blank">
                    <img src="assets/images/header/youtube.png" alt="" />
                  </a>
                </li>
            </ul>
            <a href="https://www.dextools.io/app/en/ether/pair-explorer/0x80464c5024253974506e8dd36b8ee49791c1f1ea" target="_blank">
            <button className="btn_5">View Chart</button></a>
            <ConnectButton />
          </div>
        </div>
      </div>
    </header>
  );
}
