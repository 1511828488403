import React from "react";

function Tokenomics() {
  return (
    <section className="tokenomic_area section_padding" id="Tokenomics">
      <div className="container">
        <div className="tekenomic">
          <div className="token_left">
            <img src="assets/images/tokenomic/1.png" alt="" />
            <p>
              The tokenomics are flexible and independent of the presale
              sellout. In this case, the tokenomics will be cut down as a
              percentage in favor of current investors!
            </p>
          </div>
          {/* RIGHT SIDE START HERE */}
          <div className="tokenomic_right">
            <h3>TOKENOMICS</h3>
            <p>
              The tokenomics of BTCINU are deeply researched and inspired by
              successful methods. Thus they aim for the maximum growth and
              minimum limits at every time and phase.
            </p>
            <ul className="token_ul">
              <li>
                <h4>69.0%</h4>
                <p>
                  <span>Presale</span> <br /> (14.490.000 BTCINU)
                </p>
              </li>
              <li>
                <h4>20.0%</h4>
                <p>
                  <span>CEX&amp;DEX Liquidity</span> <br /> (4.200.000 BTCINU)
                </p>
              </li>
              <li>
                <h4>10.0%</h4>
                <p>
                  <span>Marketing</span> <br /> (2.100.000 BTCINU)
                </p>
              </li>
              <li>
                <h4>1.0%</h4>
                <p>
                  <span>Team</span> <br /> (210.000 BTCINU)
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Tokenomics;
