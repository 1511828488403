import React from "react";

function Footer() {
  return (
    <footer className="footer_area">
      <div className="join_main_footer">
        <div className="join_area section_padding">
          <div className="container">
            <div className="main_join">
              <div className="join_left">
                <h3>READY TO JOIN THE BTCINU COMMUNITY?</h3>
                <p>
                  The bigger our community, the more gains are to be made.
                  Become a part of the revolutionizing BTCINU Memecoin and join
                  our telegram community group right now!
                </p>
              </div>
              <div className="join_right">
                <a href="https://t.me/btcinucoin" target="_blank">Join Now</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="footer_main">
          <div className="footer">
            <div className="footer_logo">
              <div className="logo">
                <a href="#">
                  <img src="assets/images/logo.png" alt="" />
                  <span className="footer_logo_text">Bitcoin Inu</span>
                </a>
              </div>
              <p>
                Disclaimer: Cryptocurrency may be unregulated in your
                jurisdiction. The value of cryptocurrencies may go down as well
                as up. Profits may be subject to capital gains or other taxes
                applicable in your jurisdiction.
              </p>
                  {/* Show on Desktop */}
                <div className="solidproof mobile1-hide"> <a href="https://app.solidproof.io/projects/btcinu?audit_id=808" target="_blank">
              <img src="assets/images/solidprooflogo.png" alt="" className="solidproofimg"/></a>
              </div>

                  {/* Show only on Mobile */}
                  <div className="solidproofmobile desktop1-hide"> <a href="https://app.solidproof.io/projects/btcinu?audit_id=808" target="_blank">
              <img src="assets/images/solidprooflogo.png" alt="" className="solidproofmobileimg"/></a>
              </div>

            </div>
            <div className="footer_item">
              <h4>Quick links</h4>
              <ul>
                <li>
                  <a href="#Roadmap">Roadmap</a>
                </li>
                <li>
                  <a href="#how_to_buy">How to buy</a>
                </li>
                <li>
                  <a href="#faq">FAQ</a>
                </li>
                <li>
                  <a href="#Tokenomics">Tokenomics</a>
                </li>
              </ul>
            </div>
            <div className="footer_item">
              <h4>Company</h4>
              <ul>
                <li>
                  <a
                    href="assets/pdf/Privacy Policy BTCINU.pdf"
                    target="_blank"
                  >
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a
                    href="assets/pdf/Terms & Condition BTCINU.pdf"
                    target="_blank"
                  >
                    Terms &amp; Conditions
                  </a>
                </li>
                <li>
                  <a
                    href="assets/pdf/Cookies Policy BTCINU.pdf"
                    target="_blank"
                  >
                    Cookies
                  </a>
                </li>
              </ul>
            </div>
            <div className="footer_item">
              <h4>Get in touch</h4>
              <ul className="social_footer">
                <li>
                  <a href="https://t.me/btcinucoin" target="_blank">
                    <img src="assets/images/header/teligram.png" alt="" />
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/btcinucoin" target="_blank">
                    <img src="assets/images/header/x.png" alt="" />
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/btcinu" target="_blank">
                    <img src="assets/images/header/instagram.png" alt="" />
                  </a>
                </li>
                <li>
                  <a href="https://youtube.com/@bitcoininu?si=yOo47Q2UKA0AuVog" target="_blank">
                    <img src="assets/images/header/youtube.png" alt="" />
                  </a>
                </li>
              </ul>
              <ul>
                <li>
                  <a href="mailto:contact@bitcoininu.io">
                    contact@bitcoininu.io
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <hr />
          <div className="footer_copy">
            <p>Copyright © 2023 Company Agency. All rights reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
