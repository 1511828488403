import React, { useEffect, useState, useContext } from "react";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { Box, useMediaQuery } from "@mui/material";
import {
  preSaleReadFunction,
  preSaleWriteFunction,
  tokenReadFunction,
  usdtWriteFunction,
} from "../ConnectivityAssets/hooks";
import { ToastNotify } from "../components/SmallComponents/AppComponents";
import Loading from "../components/SmallComponents/loading";
import { formatUnits, parseUnits } from "viem";
import { presaleAddress } from "../ConnectivityAssets/environment";
import { AppContext } from "./../utils";
import TimerCountDown from "../components/SmallComponents/PresaleTimer";
import CountdownTimer from "../components/SmallComponents/CountdownTimer";

function HeroSection() {
  const { account } = useContext(AppContext);
  const { open } = useWeb3Modal();
  const [token, setToken] = useState("USDT");
  const [enteredAmountETH, setEnteredAmountETH] = useState("");
  const [enteredAmountUSDT, setEnteredAmountUSDT] = useState("");
  const [endTime, setendTime] = useState(1674475330);
  const [callFunction, setCallFunction] = useState(true);
  const [receivedTokensETH, setreceivedTokensETH] = useState("");
  const [receivedTokensUSDT, setreceivedTokensUSDT] = useState("");
  const [tokenPerUSDT, settokenPerUSDT] = useState(0);
  const [tokenPerETH, settokenPerETH] = useState(0);
  const [tokenPrice, settokenPrice] = useState(0);
  const [currentStage, setcurrentStage] = useState(0);
  const [loading, setloading] = useState(false);
  const [amountRaisedForAll, setamountRaisedForAll] = useState(0);
  const [progressBarForAll, setprogressBarForAll] = useState(0);
  const [userPurchasedTokens, setuserPurchasedTokens] = useState(0);
  const [chkTokenEnterState, setChkTokenEnterState] = useState(true);
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });
  const toLocalFormat = (val) => {
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const initVoidSigner = async () => {
    try {
      let dec = await tokenReadFunction("decimals");
      let stage = await preSaleReadFunction("presaleId");
      setcurrentStage(+stage?.toString());
      let usdtToToken = await preSaleReadFunction("usdtToTokens", [
        stage?.toString(),
        "1000000",
      ]);
      settokenPerUSDT(+formatUnits(usdtToToken?.toString(), +dec?.toString()));
      let ethToToken = await preSaleReadFunction("ethToTokens", [
        stage?.toString(),
        "1000000000000000000",
      ]);
      settokenPerETH(+formatUnits(ethToToken?.toString(), +dec?.toString()));
      let presaleData = await preSaleReadFunction("presale", [
        +stage?.toString(),
      ]);
      setendTime(+presaleData[1]?.toString());
      settokenPrice(+formatUnits(presaleData[2]?.toString(), +dec?.toString()));
      let totalRaisedAmount = 0;
      for (let index = 1; index <= +stage?.toString(); index++) {
        let presaleData = await preSaleReadFunction("presale", [+index]);
        totalRaisedAmount += +parseFloat(
          +formatUnits(presaleData[6]?.toString(), 6)
        ).toFixed(0);
      }
      setamountRaisedForAll(toLocalFormat(+totalRaisedAmount));
      let progForAll = (+totalRaisedAmount / 683098) * 100;
      setprogressBarForAll(+progForAll);
      setCallFunction(false);
    } catch (error) {
      setCallFunction(false);
      console.log(error, "ERROR VoidSigner Data");
    }
  };
  useEffect(() => {
    initVoidSigner();
  }, [callFunction]);
  useEffect(() => {
    if (account) {
      (async () => {
        let dec = await tokenReadFunction("decimals");
        let userData = await preSaleReadFunction("userClaimData", [account]);
        setuserPurchasedTokens(
          +formatUnits(userData[1]?.toString(), +dec?.toString())
        );
      })();
    }
  }, [account]);
  const buyHadler = async () => {
    if (account) {
      if (!enteredAmountETH && token === "ETH") {
        setAlertState({
          open: true,
          message: `Error! Please enter an amount`,
          severity: "error",
        });
      } else if (!enteredAmountUSDT && token === "USDT") {
        setAlertState({
          open: true,
          message: `Error! Please enter an amount`,
          severity: "error",
        });
      } else {
        try {
          setloading(true);
          if (token === "USDT") {
            await usdtWriteFunction("approve", [
              presaleAddress,
              parseUnits(enteredAmountUSDT.toString(), 6).toString(),
            ]);
            await preSaleWriteFunction("buyWithUSDT", [
              parseUnits(enteredAmountUSDT.toString(), 6).toString(),
            ]);
          } else {
            await preSaleWriteFunction(
              "buyWithEth",
              [],
              parseUnits(enteredAmountETH.toString(), 18).toString()
            );
          }
          setAlertState({
            open: true,
            message: "Success! Transaction Confirmed",
            severity: "success",
          });
          setEnteredAmountETH("");
          setreceivedTokensUSDT(0);
          setreceivedTokensETH(0);
          setCallFunction(true);
          setloading(false);
        } catch (error) {
          setloading(false);
          setAlertState({
            open: true,
            message: error?.shortMessage,
            severity: "error",
          });
        }
      }
    } else {
      setAlertState({
        open: true,
        message: `Error! Please connect your wallet.`,
        severity: "error",
      });
    }
  };
  const calculatorETHOne = async (e) => {
    try {
      setToken("ETH");
      let v = +e.target.value;
      setEnteredAmountETH(e.target.value);
      let tokenETH = +tokenPerETH * v;
      setreceivedTokensETH(tokenETH);
    } catch (error) {}
  };
  const calculatorETHTwo = async (e) => {
    try {
      setToken("ETH");
      let v = +e.target.value;
      setreceivedTokensETH(e.target.value);
      let tokenETH = v / +tokenPerETH;
      setEnteredAmountETH(tokenETH);
    } catch (error) {}
  };
  const calculatorUSDTOne = async (e) => {
    try {
      setToken("USDT");
      let v = +e.target.value;
      setEnteredAmountUSDT(e.target.value);
      let tokenUSDT = +tokenPerUSDT * v;
      setreceivedTokensUSDT(tokenUSDT);
    } catch (error) {}
  };
  const calculatorUSDTTwo = async (e) => {
    try {
      setToken("USDT");
      let v = +e.target.value;
      setreceivedTokensUSDT(e.target.value);
      let tokenUSDT = v / +tokenPerUSDT;
      setEnteredAmountUSDT(tokenUSDT);
    } catch (error) {}
  };
  const claimHandler = async () => {
    if (account) {
      try {
        setloading(true);
        await preSaleWriteFunction("claimAmount");
        setloading(false);
        setAlertState({
          open: true,
          message: `Transection Completed!`,
          severity: "success",
        });
      } catch (error) {
        setloading(false);
        setAlertState({
          open: true,
          message: error?.shortMessage,
          severity: "error",
        });
      }
    } else {
      setAlertState({
        open: true,
        message: `Error! Please connect your wallet.`,
        severity: "error",
      });
    }
  };





  return (
    <section className="banner_area" id="home">
      <Loading loading={loading} />
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="banner_text">
              <h4>BUY NOW BEFORE IT'S TOO LATE</h4>
              <h2> YOU WANT BTC 2.0? YOU GET BTC 2.0! HERE IS BTCINU!</h2>
              <p>
                The number one cryptocurrency, with more than 50% dominance will
                now enter the Memecoin world to show its strength! BTCINU aims to
                be the number one Memecoin, over DOGECOIN, SHIB and PEPE!
              </p>
              {/* <button class="btn_1">TAKE A DIVE</button> */}
              <div className="bammer_btnn">
                <a
                  href="assets/pdf/Whitepaper BTCINU.pdf"
                  target="_blank"
                  className="btn_1"
                >
                  Whitepaper &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                  <i className="fa-solid fa-arrow-right" />
                </a>
                <a href="#Tokenomics" className="btn_2 tokenomics_scroll">
                  Tokenomics
                </a>
              </div>
              <div className="banner_banana">
                <img src="assets/images/banner/bticoin.png" alt="" />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="banner_card">
              <div className="monkey_card">
                <div className="monkey_countdown">
                  <div id="countdown">
                    {/* <TimerCountDown time={+endTime} /> */}
                    {/* <p className="align-center">Claiming & Uniswap Listing starts in</p> */}

                    {/* <CountdownTimer /> */}




                    {/* <ul>
                      <li>
                        <p>
                          <span id="days" /> <br />{" "}
                          <span className="count_name">DAYS</span>
                        </p>
                      </li>
                      <li>
                        <p>:</p>
                      </li>
                      <li>
                        <p>
                          <span id="hours" /> <br />{" "}
                          <span className="count_name">HOURS</span>
                        </p>
                      </li>
                      <li>
                        <p>:</p>
                      </li>
                      <li>
                        <p>
                          <span id="minutes" /> <br />{" "}
                          <span className="count_name">MINUTES</span>
                        </p>
                      </li>
                      <li>
                        <p>:</p>
                      </li>
                      <li>
                        <p>
                          <span id="seconds" /> <br />{" "}
                          <span className="count_name">SECONDS</span>
                        </p>
                      </li>
                    </ul>
 */}









                  </div>
                </div>
                <div className="monkey_progress">
                  <div className="progress">
                    <div
                      className="bar"
                      style={{ width: `${+progressBarForAll}%` }}
                    ></div>
                  </div>
                </div>
                <div className="monekey_info">
                  {/* <p>Sold Out: ${amountRaisedForAll}/$687,896.37</p> */}
                  <p>Sold Out: $687,896.37/$687,896.37</p>

                  {/* <p>Listing Price: $0.06</p> */}
                  <p>Now listed on UNISWAP - Buy Now!</p>
        
                </div>
                <div className="monkey_form_title">
                  <span />
                  {/* <p>
                    1 BTCINU = $
                    {+tokenPrice > 0
                      ? parseFloat(1 / +tokenPrice).toFixed(3)
                      : "0.00"}
                  </p> */}
                  <p>1 BTCINU = $0.06</p>       
                  <span />
                </div>
                
                
                <div className="monekey_tabs">
                  <ul className="monekey_button">
                  <li>
                      <button className="active" id="ETH">
                        <img src="assets/images/1.svg" alt="" />
                        <p>ETH</p>
                      </button>
                    </li>
                    <li>
                      <button id="USDT">
                        <img src="assets/images/2.svg" alt="" />
                        <p>USDT</p>
                      </button>
                    </li>
                    
                  </ul>
                  <div className="monkey_tabs_content">

                    {/* here below */}
                    <div className="monkey_form ETH">
                      <div className="from_input">
                        <div className="form_group">
                          <label htmlFor="">Amount in ETH</label>
                          <input
                            value={
                              chkTokenEnterState
                                ? +receivedTokensETH > 0
                                  ? parseFloat(enteredAmountETH).toFixed(2)
                                  : ""
                                : enteredAmountETH
                            }
                            onChange={(e) => {
                              setChkTokenEnterState(false);
                              calculatorETHOne(e);
                            }}
                            type="number"
                            defaultValue={0}
                          />
                        </div>
                        <div className="img_monkey">
                          <img src="assets/images/1.svg" alt="" />
                        </div>
                      </div>
                      <div className="from_input">
                        <div className="form_group">
                          <label htmlFor="">Amount in BTCINU</label>
                          <input
                            value={
                              !chkTokenEnterState
                                ? +enteredAmountETH > 0
                                  ? parseFloat(receivedTokensETH).toFixed(2)
                                  : ""
                                : receivedTokensETH
                            }
                            onChange={(e) => {
                              setChkTokenEnterState(true);
                              calculatorETHTwo(e);
                            }}
                            type="number"
                            defaultValue={0}
                          />
                        </div>
                        <div className="img_monkey">
                          <img src="assets/images/logo.png" alt="" />
                        </div>
                      </div>
                            <br />
                      {account ? (
                        <div
                          onClick={() => buyHadler()}
                          className="form_submit_btn"
                        >
                          {/* <button>Buy Now</button> */}
                        </div>
                      ) : (
                        <div
                          onClick={async () => {
                            await open();
                          }}
                          className="form_submit_btn"
                        >
                          <button>CONNECT WALLET</button>

                         

                          <p className="align-center">Connect wallet to claim your tokens</p>
                          

                          
                        </div>
                      )}
                      <div className="unibutton"><a href="https://app.uniswap.org/tokens/ethereum/0x584a4dd38d28fd1ea0e147ba7b70aed29a37e335" target="blank" >
                          <div className="btn10">Buy now on Uniswap</div></a></div>
                      <Box mt={1} />
                      {userPurchasedTokens > 0 && (
                        <div
                          onClick={() => claimHandler()}
                          className="form_submit_btn"
                        >
                          <button>CLAIM BTCINU</button>
                        </div>
                      )}
                    </div>
                    <div className="monkey_form USDT">
                      <div className="from_input">
                        <div className="form_group">
                          <label htmlFor="">Amount in USDT</label>
                          <input
                            value={
                              chkTokenEnterState
                                ? +receivedTokensUSDT > 0
                                  ? parseFloat(enteredAmountUSDT).toFixed(2)
                                  : ""
                                : enteredAmountUSDT
                            }
                            onChange={(e) => {
                              setChkTokenEnterState(false);
                              calculatorUSDTOne(e);
                            }}
                            type="number"
                            defaultValue={0}
                          />
                        </div>
                        {/* <div > */}
                        <img
                          className="img_monkey"
                          src="assets/images/form/2.svg"
                          alt=""
                        />
                        {/* </div> */}
                     
                     
                     
                      </div>
                      <div className="from_input">
                        <div className="form_group">
                          <label htmlFor="">Amount in BTCINU</label>
                          <input
                            value={
                              !chkTokenEnterState
                                ? +enteredAmountUSDT > 0
                                  ? parseFloat(receivedTokensUSDT).toFixed(2)
                                  : ""
                                : receivedTokensUSDT
                            }
                            onChange={(e) => {
                              setChkTokenEnterState(true);
                              calculatorUSDTTwo(e);
                            }}
                            type="number"
                            defaultValue={0}
                          />
                        </div>
                        <div className="img_monkey">
                          <img src="assets/images/logo.png" alt="" />
                        </div>
                      </div>




                      
                      {account ? (
                        <div
                          onClick={() => buyHadler()}
                          className="form_submit_btn"
                        >
                          {/* <button>Buy Now</button> */}
                        </div>
                      ) : (
                        <div
                          onClick={async () => {
                            await open();
                          }}
                          className="form_submit_btn"
                        >
                          <button>CONNECT WALLET</button>
                        </div>
                      )}
                      <Box mt={1} />
                      {userPurchasedTokens > 0 && (
                        <div
                          onClick={() => claimHandler()}
                          className="form_submit_btn"
                        >
                          <button>CLAIM BTCINU</button>
                        </div>
                      )}
                    </div>
                    <div className="monekey_info">
                      <p style={{ fontSize: "17px" }}>
                        Your BTCINU: {userPurchasedTokens}
                      </p>
                     
                    </div>
                    
                  </div>
                  
                </div>
                
                <div className="monkey_footer">
                  <img src="assets/images/svg.svg" alt="" />
                  <a href="#how_to_buy">How to Buy</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroSection;
