import React from "react";

function Roadmap() {
  return (
    <section className="roadmap_area section_padding" id="Roadmap">
      <div className="container">
        <div className="section_title">
          <h2>ROADMAP</h2>
          <p>
            For the coming months we are pursuing to reach the milestones and
            goals listed in the Roadmap below.
          </p>
        </div>
        <div className="roadmap_main">
          <div className="dot_white_1">
            <img src="assets/images/roadmap/line1.png" alt="" />
          </div>
          <div className="dot_white_2">
            <img src="assets/images/roadmap/line2.png" alt="" />
          </div>
          <div className="dot_white_3">
            <img src="assets/images/roadmap/line1.png" alt="" />
          </div>
          {/* single roadmap  */}
          <div className="roadmap_single">
            <a href="#">PHASE 1</a>
            <h3>Q3 2023</h3>
            <hr />
            <ul>
              <li>
                <p>
                  <span />
                  Intensive Market Research
                </p>
              </li>
              <li>
                <p>
                  <span />
                  Token Development
                </p>
              </li>
              <li>
                <p>
                  <span />
                  Website Development
                </p>
              </li>
              <li>
                <p>
                  <span />
                  Advanced Marketing Research
                </p>
              </li>
            </ul>
          </div>
          {/* single roadmap  */}
          <div className="roadmap_single">
            <a href="#">PHASE 2</a>
            <h3>Q4 2023</h3>
            <hr />
            <ul>
              <li>
                <p>
                  <span />
                  Presale Start
                </p>
              </li>
              <li>
                <p>
                  <span />
                  Marketing Campaign
                </p>
              </li>
              <li>
                <p>
                  <span />
                  Social Media Campaign
                </p>
              </li>
              <li>
                <p>
                  <span />
                  Community Events
                </p>
              </li>
            </ul>
          </div>
          {/* single roadmap  */}
          <div className="roadmap_single">
            <a href="#">PHASE 3</a>
            <h3>Q1 2024</h3>
            <hr />
            <ul>
              <li>
                <p>
                  <span />
                  Potential Token Burn (based on presale results)
                </p>
              </li>
              <li>
                <p>
                  <span />
                  Presale Token Claim
                </p>
              </li>
              <li>
                <p>
                  <span />
                  CMC Listing
                </p>
              </li>
              <li>
                <p>
                  <span />
                  Partnerships
                </p>
              </li>
              <li>
                <p>
                  <span />
                  DEX LISTING
                </p>
              </li>
            </ul>
          </div>
          {/* single roadmap  */}
          <div className="roadmap_single">
            <a href="#">PHASE 4</a>
            <h3>Q2 2024</h3>
            <hr />
            <ul>
              
              <li>
                <p>
                  <span />
                  CEX Listing
                </p>
              </li>
              
              <li>
                <p>
                  <span />
                  Community Engagement
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Roadmap;
