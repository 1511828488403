import React from "react";

function Map() {
  return (
    <section className="map_area section_padding">
      <div className="container">
        <div className="maps">
          <div className="maps_left">
            <h3>The Bitcoin INU UNIQUE Solution</h3>
            <ul className="map_ul">
              <li>
                <img src="assets/images/map/check.png" alt="Check Image" />
                <div className="map_text">
                  <h4>EASY ACCESS WORLDWIDE</h4>
                  <p>
                    Faster than Bitcoin and available everywhere, BTCINU
                    delivers a modern solution for many blockchain problems.
                  </p>
                </div>
              </li>
              <li>
                <img src="assets/images/map/check.png" alt="Check Image" />
                <div className="map_text">
                  <h4>INSTANT GAINS</h4>
                  <p>
                    The Presale prices are much lower than the launch price,
                    early Presale investors have assured profits at launch date.
                  </p>
                </div>
              </li>
              <li>
                <img src="assets/images/map/check.png" alt="Check Image" />
                <div className="map_text">
                  <h4>Incredible trend potential</h4>
                  <p>
                    Since BTCINU delivers an unique and single idea of bringing
                    the giant BTC into a whole new space, BTCINU might repeat
                    the SHIB price chart!
                  </p>
                </div>
              </li>
              <li>
                <img src="assets/images/map/check.png" alt="Check Image" />
                <div className="map_text">
                  <h4>NO LIMITS</h4>
                  <p>
                    Since the presale is hardcapped to an extremely low
                    MarketCap, there is a huge growth potential in BTCINU after
                    launch.
                  </p>
                </div>
              </li>
            </ul>
          </div>
          <div className="maps_right">
            <img src="assets/images/map/maps.png" alt="Maps" />
            <div className="maps_video border_line">
            <iframe src="https://www.youtube.com/embed/HP7NUWu8bK0?si=Aq7Gc73sgshT4iTX" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Map;
