import { useContext, useState, useEffect } from "react";
import Header from "./components/Header";
import HeroSection from "./pages/HeroSection";
import { useNetwork } from "wagmi";
import { AppContext } from "./utils";
import NetworkSwitch from "./NetworkSwitch";
import Features from "./pages/Features";
import HowToBuy from "./pages/HowToBuy";
import Map from "./pages/Map";
import Tokenomics from "./pages/Tokenomics";
// import Stages from "./pages/Stages";
import Roadmap from "./pages/Roadmap";
import Faqs from "./pages/Faqs";
import Footer from "./components/Footer";


function App() {
  const { account } = useContext(AppContext);
  const [openNetworkSwitch, setOpenNetworkSwitch] = useState(false);
  const { chain } = useNetwork();

  useEffect(() => {
    if (account && chain && chain?.id !== 1) {
      setOpenNetworkSwitch(true);
    }
  }, [chain, account]);
  return (
    <>
      <NetworkSwitch open={openNetworkSwitch} setOpen={setOpenNetworkSwitch} />
      <Header />

      <HeroSection />
      <Features />
      <HowToBuy />
      <Map />
      <Tokenomics />
      {/* <Stages /> */}
      <Roadmap />
      <Faqs />
      <Footer />
    </>
  );
}

export default App;
